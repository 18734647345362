<template>
    <div class="role_main">
        <!-- 标题 -->
        <div class="title_page">
            <div class="sig"></div>
            <div class="page_name">角色列表</div>
        </div>
        <!-- 表格内容 -->
        <div class="avatar_table">
            <el-card>
                <!-- 添加 删除按钮部分 -->
                <div class="add_del_btn">
                    <el-button type="primary" class="addBtn" @click="addition">添加
                    </el-button>
                    <el-button class="delBtn" @click="deltion">删除</el-button>
                </div>
                <!-- 内容表格部分 -->
                <div class="table_init">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                        @selection-change="handleSelectionChange"
                        :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="uid" label="ID" width="180">
                        </el-table-column>
                        <el-table-column prop="username" label="账号">
                        </el-table-column>
                        <el-table-column prop="phone" label="手机号">
                        </el-table-column>
                        <el-table-column prop="email" label="邮箱" width="160">
                        </el-table-column>
                        <el-table-column prop="auth" label="角色权限">
                        </el-table-column>
                        <el-table-column prop="lastlogin" label="最后登录时间" width="180">
                        </el-table-column>
                        <el-table-column prop="status" label="状态" width="100">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <span style="margin-right:16px" @click="handle_Edit(scope.row.uid)">编辑</span>
                                <span @click="handle_Delet(scope.row.uid)">删除</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 分页部分 -->
                <Paging :count="count" :initData="initData" @changePage="handle_changePage"></Paging>
            </el-card>
        </div>
        <!-- 弹框部分 -->
        <!-- 添加编辑弹框 -->
        <el-dialog :visible.sync="add_edit_dialog" width="828px" :before-close="handleClose" @close="handle_reset">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">{{title_name}}</div>
            </div>
            <!-- 表单验证内容 -->
            <el-form label-width="120px">
                <el-form-item label="账号">
                    <el-input v-model="account" placeholder="请输入账号" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="昵称">
                    <el-input v-model="nickname" placeholder="请输入用户昵称" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="phone" placeholder="请输入手机号" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="照片" class="img_load">
                    <div style="display:flex">
                        <el-input style="width:376px;height:30px" placeholder="请上传1张头像" disabled></el-input>
                        <el-upload class="upload-demo" action="https://education.ztxinde.com/auth_user_upload"
                            :headers=myHeaders accept=".png,.gif,.jpg,.jpeg" name="image"
                            :on-success="handleAvatarSuccess" :show-file-list="false">
                            <el-button type="primary" style="width:80px;height:30px;margin-left:16px" size="small">上传
                            </el-button>
                        </el-upload>
                    </div>
                </el-form-item>
                <el-form-item v-if="img_show">
                    <img style="width:100px;height:100px" :src="img_url" alt="">
                </el-form-item>
                <el-form-item label="邮箱">
                    <el-input v-model="mail" placeholder="请输入邮箱地址" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="角色权限">
                    <el-select v-model="role_id" placeholder="请选择角色权限" clearable size="mini">
                        <el-option v-for="item in role_arr" :key="item.uid" :label="item.user_name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input type="password" v-model="password" show-password placeholder="请输入密码(密码限制在6-20位)" clearable
                        size="mini"></el-input>
                </el-form-item>
                <el-form-item label="确认密码">
                    <el-input type="password" v-model="pwd" show-password placeholder="请再次输入密码(密码限制在6-20位)" clearable
                        size="mini">
                    </el-input>
                </el-form-item>
                <el-form-item label="是否有支付">
                    <el-radio v-model="pay_radio" label="1" @change="handle_Payraido">有</el-radio>
                    <el-radio v-model="pay_radio" label="2" @change="handle_Payraido">无</el-radio>
                </el-form-item>
                <div v-if="pay_show">
                    <el-form-item label="商户号">
                        <el-input v-model="merchant" placeholder="请输入商户号" clearable size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="证书序列号">
                        <el-input v-model="certificate" placeholder="请输入证书序列号" clearable size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="私钥" class="img_load">
                        <div style="display:flex">
                            <el-input style="width:376px;height:30px" :placeholder="key_tip" disabled>
                            </el-input>
                            <el-upload class="upload-demo" action="https://education.ztxinde.com/auth_user_key_upload"
                                :headers=myHeaders name="image" :on-success="handle_Private" accept=".pem"
                                :show-file-list="false">
                                <el-button type="primary" style="width:80px;height:30px;margin-left:16px" size="small">
                                    上传
                                </el-button>
                            </el-upload>
                        </div>
                    </el-form-item>
                    <el-form-item label="微信支付证书" class="img_load">
                        <div style="display:flex">
                            <el-input style="width:376px;height:30px" :placeholder="wix_tip" disabled>
                            </el-input>
                            <el-upload class="upload-demo" action="https://education.ztxinde.com/auth_user_key_upload"
                                :headers=myHeaders name="image" :on-success="handle_Success" accept=".pem"
                                :show-file-list="false">
                                <el-button type="primary" style="width:80px;height:30px;margin-left:16px" size="small">
                                    上传
                                </el-button>
                            </el-upload>
                        </div>
                    </el-form-item>
                    <el-form-item label="微信支付密钥">
                        <el-input v-model="cost_num" placeholder="请输入微信支付密钥" clearable size="mini"></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="状态">
                    <el-radio v-model="radio" label="1">正常</el-radio>
                    <el-radio v-model="radio" label="2">禁用</el-radio>
                </el-form-item>
            </el-form>
            <!-- 提交 重置 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="add_confirm" @click="handle_confirm">{{btn_name}}</el-button>
                <el-button class="add_Reset" @click="handle_reset">重置</el-button>
            </div>
        </el-dialog>
        <!-- 删除弹框 -->
        <el-dialog :visible.sync="del_dialog" width="567px" :before-close="handleClose" @close="remove_Fn">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">删除</div>
            </div>
            <div class="del_content">{{del_tip}}</div>
            <!-- 提交 取消 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="del_confirm" @click="handle_del">确定</el-button>
                <el-button class="del_Reset" @click="remove_Fn">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
let auth_token = sessionStorage.getItem("Token");
import Paging from '@/components/Paging';
import { getRoleApi, showRoleApi, addRoleApi, editRoleApi, delectRoleApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // token
            myHeaders: {
                'Token': auth_token
            },
            // 图片地址
            img_url: '',
            // 上传图片是否显示
            img_show: false,
            // 弹框显示隐藏
            add_edit_dialog: false,   //添加编辑弹框
            del_dialog: false,    //删除弹框
            // 每条数据id
            id: '',
            // 分页
            page: 1,
            count: 0,
            max: 10,
            //  表格数据
            tableData: [],
            multipleSelection: [],
            // 弹框标题  是添加还是编辑
            title_name: '',
            btn_name: '',
            // *********表单验证内容*********
            account: '',   //账号
            nickname: '',    //昵称
            phone: '',    //手机号
            role_arr: [],   //角色权限
            role_id: '',
            mail: '',     //邮箱
            password: '',   //密码
            pwd: '',     //确认密码
            radio: '1',   //状态
            state_ra: 1,
            // 删除数据的数组
            del_arr: [],
            // 删除提示语
            del_tip: '',
            pay_show: true,
            pay_radio: '1',   //是否有支付
            pay_state: 1,
            merchant: '',     //商户号
            certificate: '',    //证书序列号
            key_name: '',    //私钥文件名
            wix_name: '',    //支付证书名
            key_tip: '',
            wix_tip: '',
            cost_num: '',
        }
    },
    created () {
        this.initData();
		
    },
	mounted() {
		
	},
    watch: {
        // 监听分页数量的变化
        count () {
            if (this.count == (this.page - 1) * this.max && this.count != 0) {
                this.page -= 1;
                this.initData()
            }
        }
		
    },
    methods: {
        // 子组件传过来的当前页数
        handle_changePage (data) {
            this.page = data;
        },
        // 初始化数据
        initData () {
            getRoleApi({
                page: this.page,   //当前页
                from: 1      //来源
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.data;
					console.log(this.tableData);
					for(let i=0;i<this.tableData.length;i++) {
						this.tableData[i].lastlogin = this.formatTime(this.tableData[i].lastlogin * 1000)
					}
                    this.count = res.count;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 表格的方法
        toggleSelection (rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange (val) {
            this.multipleSelection = val;
        },
        // 弹窗关闭
        handleClose () {
            this.add_edit_dialog = false;
            this.del_dialog = false;
        },
        // 点击添加按钮  获取角色权限
        addition () {
            this.add_edit_dialog = true;
            this.title_name = '添加';
            this.btn_name = '确认添加';
            this.key_tip = '请上传私钥文件(文件后缀为.pem文件)';
            this.wix_tip = '请上传微信支付证书(文件后缀为.pem文件)';
            showRoleApi({
                type: 1,
                from: 1
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.role_arr = res.auth;  //获取角色权限
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 上传图片成功
        handleAvatarSuccess (res, file, fileList) {
            if (res.status.code == 200) {
                this.$message.success('图片上传成功！');
                this.img_show = true;
                this.img_url = res.status.img;  // 图片地址
            } else {
                this.$message.error('图片上传失败！')
            }
        },
        // 是否有支付  
        handle_Payraido () {
            if (this.pay_radio == '1') {
                this.pay_show = true;
                this.pay_state = 1
            } else {
                this.pay_show = false;
                this.pay_state = 2
            }
        },
        // 私钥上传成功
        handle_Private (res, file, fileList) {
            if (res.status.code == 200) {
                this.$message.success('文件上传成功！');
                this.key_name = res.status.name;  // 文件名
                this.key_tip = '已上传文件为：' + this.key_name;
            } else {
                this.$message.error('文件上传失败！')
            }
        },
        // 支付凭证上传成功
        handle_Success (res, file, fileList) {
            if (res.status.code == 200) {
                this.$message.success('文件上传成功！');
                this.wix_name = res.status.name;  // 文件名
                this.wix_tip = '已上传文件为：' + this.wix_name;
            } else {
                this.$message.error('文件上传失败！')
            }
        },
        // 点击编辑按钮
        handle_Edit (id) {
            this.add_edit_dialog = true;
            this.id = id;
            this.title_name = '编辑';
            this.btn_name = '确认修改';
            showRoleApi({
                type: 2,
                from: 1,
                id: id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.role_arr = res.auth;  //获取角色权限
                    this.account = res.data.username;  //账号
                    this.nickname = res.data.nickname;   //昵称
                    this.phone = res.data.phone;  //手机号
                    this.img_url = res.data.avatar;   //图片
                    if (this.img_url == '') {
                        this.img_show = false;
                    } else {
                        this.img_show = true;
                    };
                    this.mail = res.data.email;   //邮箱
                    this.password = res.data.rel_pwd;   //密码
                    this.pwd = res.data.rel_pwd;   //密码
                    this.state_ra = res.data.status;  //状态
                    if (this.state_ra == 1) {
                        this.radio = '1'
                    } else if (this.state_ra == 2) {
                        this.radio = '2'
                    };
                    this.role_id = res.data.auth_id;  //角色权限id
                    this.pay_state = res.data.has_pay;
                    if (this.pay_state == 1) {
                        this.pay_radio = '1';
                        this.pay_show = true;
                        this.merchant = res.data.mch_id;   //商户号
                        this.certificate = res.data.serial_number;   //序列号
                        this.key_name = res.data.key_path;   //私钥
                        this.wix_name = res.data.cert_path;   //支付证书
                        this.key_tip = '已上传文件为：' + this.key_name;
                        this.wix_tip = '已上传文件为：' + this.wix_name;
                    } else {
                        this.pay_radio = '2';
                        this.pay_show = false;
                    };
                    this.cost_num = res.data.wx_apiv3;    //微信支付密钥
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 添加  编辑 点击提交按钮
        handle_confirm () {
            if (this.radio == '1') {
                this.state_ra = 1;
            } else if (this.radio == '2') {
                this.state_ra = 2;
            };
            if (this.title_name == '添加') {
                addRoleApi({
                    username: this.account,  //账号
                    nickname: this.nickname,   //昵称
                    phone: this.phone,    //手机号
                    avatar: this.img_url,  // 图片地址
                    email: this.mail,   //邮箱
                    auth_id: this.role_id,   //角色权限id
                    password: this.password,  //密码
                    password_confirmation: this.pwd,  //确认密码
                    status: this.state_ra,   //状态
                    from: 1,
                    has_pay: this.pay_state,    //是否有支付
                    mch_id: this.merchant,     //商户号
                    serial_number: this.certificate,   //商户序列号
                    key_path: this.key_name,    //私钥
                    cert_path: this.wix_name,    //支付证书
                    wx_apiv3: this.cost_num,     //微信支付密钥
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.initData();
                        this.handle_reset();
                        this.add_edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.title_name == '编辑') {
                editRoleApi({
                    id: this.id,
                    username: this.account,  //账号
                    nickname: this.nickname,   //昵称
                    phone: this.phone,    //手机号
                    avatar: this.img_url,  // 图片地址
                    email: this.mail,   //邮箱
                    auth_id: this.role_id,   //角色权限id
                    password: this.password,  //密码
                    password_confirmation: this.pwd,  //确认密码
                    status: this.state_ra,   //状态
                    from: 1,
                    has_pay: this.pay_state,    //是否有支付
                    mch_id: this.merchant,     //商户号
                    serial_number: this.certificate,   //商户序列号
                    key_path: this.key_name,    //私钥
                    cert_path: this.wix_name,    //支付证书
                    wx_apiv3: this.cost_num,     //微信支付密钥
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.initData();
                        this.handle_reset();
                        this.add_edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 添加  编辑  点击重置按钮  弹窗点击遮罩关闭
        handle_reset () {
            this.account = '';  //账号
            this.nickname = '';  //昵称
            this.phone = '';    // 手机号
            this.role_id = '';   // 角色权限
            this.mail = '';    //邮箱
            this.password = '';    //密码
            this.pwd = '';     //确认密码
            this.radio = '1';   //状态
            this.img_show = false;  //图片显示
            this.img_url = '';
            this.merchant = '';
            this.certificate = '';
            this.key_name = '';
            this.wix_name = '';
            this.pay_radio == '1';
            this.pay_show = true;
            this.cost_num = '';
        },
        // 点击删除按钮  单个删除按钮
        handle_Delet (id) {
            this.id = id;
            this.del_dialog = true;
            this.del_tip = '确定删除此项？';
            this.del_arr.push(id);
        },
        // 多个删除
        deltion () {
            this.del_dialog = true;
            this.del_tip = '确定删除所选中的选项？';
            for (let i = 0; i < this.multipleSelection.length; i++) {
                let del_id = this.multipleSelection[i].uid;
                this.del_arr.push(del_id);
            };
        },
        remove_Fn () {
            this.del_dialog = false;
            this.del_arr.splice(0, this.del_arr.length)
        },
        // 删除点击确定按钮
        handle_del () {
            delectRoleApi({
                id: this.del_arr,
                from: 1
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.$message.success('删除成功');
                    this.del_dialog = false;
                    this.initData();
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
		formatTime(curTime,type) {
			if(!curTime) { return '' }
			var nd = new Date(curTime)
			var y = nd.getFullYear()
			var mm = nd.getMonth() + 1
			var d = nd.getDate()
			console.log(d);
			var h = nd.getHours()
			var m = nd.getMinutes()
			if(mm < 10) { mm = '0' + mm }
			if(d < 10) { d = '0' + d }
			if(h < 10) { h = '0' + h }
			if(m < 10) { m = '0' + m }
			if(type == 'MM-DD') {
				return mm + '-' + d
			}else if(type == 'YYYY-MM-D') {
				return y + '-' + mm + '-' + d
			}else {
				return y + '-' + mm + '-' + d + ' ' + h + ':' + m
			}
		}
    }
}
</script>

<style lang="scss" scoped>
.role_main {
    width: 100%;
    height: 100%;
    // 页面标题部分
    .title_page {
        text-align: left;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .sig {
            width: 18px;
            height: 4px;
            margin-right: 8px;
            background: #2479dd;
        }
        .page_name {
            font-size: 18px;
            font-weight: 600;
        }
    }
    // 表格显示内容
    .avatar_table {
        margin-bottom: 20px;
        // 添加  删除按钮部分
        .add_del_btn {
            text-align: right;
            .el-button {
                width: 80px;
                height: 30px;
                line-height: 4px;
            }
            .addBtn {
                margin-right: 8px;
            }
        }
        // 表格内容
        .table_init {
            margin-top: 20px;
            text-align: center;
            // 操作按钮
            span {
                border: none;
                width: 24px;
                height: 18px;
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }
    // 弹框部分
    .el-dialog {
        text-align: center;
        // border-radius: 12px;
        // 标题部分
        .tipes {
            display: flex;
            align-items: center;
            text-align: left;
            position: absolute;
            left: 30px;
            top: 30px;
            .sd {
                width: 18px;
                height: 4px;
                margin-right: 8px;
                background: #2479dd;
            }
            .ax {
                color: #000000;
                font-size: 16px;
                font-weight: 600;
            }
        }
        //  添加  编辑 表单部分
        .el-form {
            text-align: left;
            margin: 30px 0 50px 100px;
            .el-form-item {
                .el-input {
                    width: 472px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 472px;
                    height: 30px;
                }
            }
            //照片上传
            .img_load {
                ::v-deep.el-input__inner {
                    width: 376px;
                    height: 30px;
                }
            }
        }
        // 删除弹框
        .del_content {
            font-size: 18px;
            font-weight: 400;
            margin: 68px 0 80px;
        }
        // 提交  重置  按钮部分
        .formBtn {
            margin-bottom: 20px;
            .el-button {
                width: 98px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
            }
            // 确认按钮  添加  编辑
            .add_confirm {
                margin-right: 16px;
            }
            // 确认按钮  删除
            .del_confirm {
                margin-right: 16px;
            }
        }
    }
}
</style>